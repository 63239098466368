
export function common () {
    var isSP = false;
    var btn = document.querySelector('.js-toggle-menu'),
        menu = document.querySelector('.js-menu'),
        //header = document.querySelector('#header'),
        menuHeight;
    var height;
    
    function setARIAState(menuState) {
        var state = menuState === 'show' ? true : false;

        menu.setAttribute('aria-expanded', state);
        btn.setAttribute('aria-expanded', state);
    }

    function toggleMenu() {
        if (menu.classList.contains('is-hide')) {
            // 非表示→表示
            btn.classList.add('collapsed');
            menu.classList.remove('is-hide');
            menu.classList.add('is-show');
            $('.toggle-txt').text('CLOSE');
            menuHeight = menu.children[0].offsetHeight;
            menu.style.maxHeight = menuHeight + 'px';
            setARIAState('show');
        } else {
            // 表示->非表示
            menu.style.maxHeight = '';
            menu.classList.remove('is-show');
            menu.classList.add('is-slideup');
            btn.classList.remove('collapsed');
            $('.toggle-txt').text('MENU');
            setARIAState();
        }
    }

    function onSlideUp() {
        if (menu.classList.contains('is-slideup')) {
            menu.classList.add('is-hide');
            menu.classList.remove('is-slideup');
        }
    }

    menu.classList.add('nav-menu-enable');
    menu.classList.add('is-hide');
    menu.addEventListener('webkitTransitionEnd', onSlideUp);
    menu.addEventListener('transitionend', onSlideUp);

    btn.addEventListener('click', toggleMenu);
    btn.setAttribute('aria-controls', menu.id);
    setARIAState();

    $('.close').on('click', function ()  {
        $('.js-menu').css({
            'max-height': 0
        });
        $('.js-menu').removeClass('is-show');
        $('.js-menu').addClass('is-slideup');
        $('.js-toggle-menu').removeClass('collapsed');
        $('.toggle-txt').text('MENU');
        setARIAState();
    });

    if ($(window).width() > 767 && $(window).width() < 905) {
        height = 62;
    } else if ($(window).width() < 767) {
        height = 0;
    } else {
        height = 40;
    }

    $('a[href^=\'#\']').on('click', function (event) {
        event.preventDefault();

        var url = this.href;

        var parts = url.split('#');
        var target = parts[1];

        var target_offset = $('#' + target).offset();
        var target_top = target_offset.top - height;

        $('html, body').animate({
            scrollTop: target_top
        }, 500);
    });

    function popupChange() {
        if (window.matchMedia('(max-width: 767px)').matches) {
            $('.popup_ico').colorbox({
                maxWidth: '100%',
                height: '100%',
                scrolling: true,
                opacity: 1,
                fixed: 'true',
                onOpen: function() {
                    $('body').css('overflow', 'hidden'); 
                },
                onClosed:function() { $('body').css('overflow', 'auto'); }
            });
        } else {
            $('.popup_ico').colorbox({
                width:'90%',
                scrolling: true,
                opacity: 0.75,
                fixed: 'false'
            });

        }
        if (window.matchMedia('(max-width: 767px)').matches) {
            $('.popup').colorbox({
                maxWidth: '100%',
                height: '100%',
                scrolling: true,
                opacity: 1,
                fixed: 'true',
                onOpen: function onOpen() {
                  $('body').css('overflow', 'hidden');
                },
                onClosed: function onClosed() {
                  $('body').css('overflow', 'auto');
                }
            });
        } else {
            $('.popup').colorbox({
                width:'95%',
                maxWidth: '848px',
                scrolling: true,
                opacity: 0.75,
                fixed: 'false'
            });
        }


        var viewportWidth = $(window).width();
        if (viewportWidth > 768) {
            $('.trailer').colorbox({
                width: '700px',
                height: '460px',
                iframe: true,
                // open: true,
                scrolling: true,
                opacity: 0.75,
                fixed: true,
                onOpen: function() {
                    $('body').addClass('trailer_wrp'); 
                },
                onClosed:function() { $('body').removeClass('trailer_wrp');  }
            });
        }
    }
    popupChange();

    window.onresize = function(){
        popupChange();
    };

    window.onload = function() {
        if ($(window).width() > 767 && $(window).width() < 905) {
            height = 62;
        } else if ($(window).width() < 767) {
            height = 0;
        } else {
            height = 40;
        }

        if (window.location.hash) {
            setTimeout(function () {
                $('html, body').scrollTop(0).show();
                $('html, body').animate({
                    scrollTop: $(window.location.hash).offset().top - height
                }, 500);
            }, 0);
        }
        else {
            $('html, body').show();
        }

    };

    window.FontAwesomeConfig = {
        searchPseudoElements: true
    };


}
